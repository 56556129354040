import React from "react";
import classes from "./style.scss";

interface Props {
	children: JSX.Element;
}

interface State {
	hasError: boolean;
}

/*
	We call them functional components and class components respectively.
	React Error Boundaries are only created from class components because they implement
	the componentDidCatch lifecycle method. There is no way to implement componentDidCatch 
	lifecycle method in functional components for now.
	Conclusion: This component won't work if it is a functional component
*/
class RootErrorBoundary extends React.Component<Props, State> {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	static getDerivedStateFromError() {
		// Update state so the next render will show the fallback UI.
		return { hasError: true };
	}

	async componentDidCatch(error) {
		this.setState({ hasError: true });

		if (error.toString().includes("TypeError: Cannot read property 'getLayer' of undefined")) {
			// Skip the bug from the drawer when is unmounting
			this.setState({ hasError: false });
		} else {
			this.setState({ hasError: true });
		}

		// You can also log the error to an error reporting service
		// logErrorToMyService(error, info);
	}

	componentDidUpdate() {
		window.onpopstate = () => {
			this.setState({ hasError: true });
		};
	}

	render() {
		if (this.state.hasError) {
			return (
				<div className={classes.pageContainer}>
					<div className={classes.bodyContainer}>
						<h1 className={classes.errorText}>OOPS,</h1>

						<h1 className={classes.errorText}>SOMETHING WENT WRONG</h1>

						<p>
							We&apos;re currently unable to show you the page you&apos;ve requested.
							Please try again later or report the issue to
						</p>

						<a href="mailto: support@dcbyte.com">support@dcbyte.com</a>

						<div className={classes.linkContainer}>
							<a className={classes.link} href="/"> Return to Home</a>
						</div>
					</div>
				</div>
			);
		}

		return this.props.children;
	}
}

export default RootErrorBoundary;
