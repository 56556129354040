import { ApolloClient } from "apollo-client";
import { InMemoryCache, defaultDataIdFromObject } from "apollo-cache-inmemory";
import { setContext } from "apollo-link-context";
import { createHttpLink } from "apollo-link-http";
import { onError } from "apollo-link-error";
import sha256 from "sha256";
import fetch from "node-fetch";

// react-apollo V2 imports
import * as apolloV2 from "@apollo/client";
import * as ApolloContextV2 from "@apollo/client/link/context";
import * as LinkErrorV2 from "@apollo/client/link/error";
import { defaultDataIdFromObject as defaultDataIdFromObjectV2, fromPromise } from "@apollo/client";
import refreshTokenRequest from "./utils/refreshToken/refreshToken";
import getServerURL from "./utils/getServerURL/getServerURL";
import constants from "./utils/constants";

const { setContext: setContextV2 } = ApolloContextV2;
const { onError: onErrorV2 } = LinkErrorV2;

const authLink = setContext((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const jwt = localStorage.getItem("dcbyte-jwt");

	// get app version
	const appVersion = sessionStorage.getItem("appVersion");

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: jwt ? `Bearer ${jwt}` : null,
			...(process.env.REACT_APP_DOMAIN !== constants.PROD_REACT_APP_DOMAIN ? { authorizationdev: jwt ? `Bearer ${jwt}` : null } : null),
			appVersion
		}
	};
});

const errorLink = onError(({ graphQLErrors, operation, forward }) => {
	if (!window.location.href.includes("localhost")) {
		const appVersionError = graphQLErrors?.filter((item) => item.message.includes("User's appVersion doesn't match"));
		if (appVersionError?.length) {
			const appVersion = appVersionError[0].message.substring(appVersionError[0].message.indexOf("(") + 1, appVersionError[0].message.length - 1);
			sessionStorage.setItem("appVersion", appVersion);
			window.location.reload();
		}
	}

	const JWTExpiredError = graphQLErrors?.filter((item) => item.message.includes("JWT token expired"));
	
	const UserRolesChangedError = graphQLErrors?.filter((item) => item.message.includes("User roles changed"));
	
	if (UserRolesChangedError?.length) {
		localStorage.clear();
		window.location.reload();
		return;
	}

	// if the access token is expired we need to try to fetch new access token
	if (JWTExpiredError?.length) {
		return fromPromise(
			refreshTokenRequest().catch(() => {
				// Handle token refresh errors e.g clear stored tokens, redirect to login, ...
				localStorage.clear();
				window.location.reload();
			})
		)
			.filter((value) => Boolean(value))
			.flatMap((accessToken: { data: string }) => {
				localStorage.setItem("dcbyte-jwt", accessToken.data);
				const oldHeaders = operation.getContext().headers;
				// modify the operation context with a new token
				operation.setContext({
					headers: {
						...oldHeaders,
						authorization: `Bearer ${accessToken.data}`
					}
				});

				// retry the request, returning the new observable
				return forward(operation);
			});
	}
});

const httpLinkUri = getServerURL();

const httpLink = createHttpLink({
	uri: httpLinkUri,
	fetch,
	credentials: "include"
});

const client = new ApolloClient({
	link: errorLink.concat(authLink.concat(httpLink)),
	cache: new InMemoryCache({
		dataIdFromObject: (responseObject: { [key: string]: any }) => {
			switch (responseObject.__typename) {
			case "Company": return responseObject.publicId ? `${responseObject.__typename}:${responseObject.publicId}` : defaultDataIdFromObject(responseObject);
			case "Site": return responseObject.publicId ? `${responseObject.__typename}:${responseObject.publicId}` : defaultDataIdFromObject(responseObject);
			default: return defaultDataIdFromObject(responseObject);
			}
		}
	})
});

// Client V3, using apollo-client v3
// We need that to user the new features and not break the old ones
const authLinkV2 = setContextV2((_, { headers }) => {
	// get the authentication token from local storage if it exists
	const jwt = localStorage.getItem("dcbyte-jwt");

	// get app version
	const appVersion = sessionStorage.getItem("appVersion");

	// return the headers to the context so httpLink can read them
	return {
		headers: {
			...headers,
			authorization: jwt ? `Bearer ${jwt}` : null,
			...(process.env.REACT_APP_DOMAIN !== constants.PROD_REACT_APP_DOMAIN ? { authorizationdev: jwt ? `Bearer ${jwt}` : null } : null),
			appVersion
		}
	};
});

const errorLinkV2 = onErrorV2(({ graphQLErrors, forward, operation }) => {
	if (!window.location.href.includes("localhost")) {
		const appVersionError = graphQLErrors?.filter((item) => item.message.includes("User's appVersion doesn't match"));

		if (appVersionError?.length) {
			const appVersion = appVersionError[0].message.substring(appVersionError[0].message.indexOf("(") + 1, appVersionError[0].message.length - 1);

			sessionStorage.setItem("appVersion", appVersion);
			window.location.reload();
		}
	}

	const JWTExpiredError = graphQLErrors?.filter((item) => item.message.includes("JWT token expired"));

	const UserRolesChangedError = graphQLErrors?.filter((item) => item.message.includes("User roles changed"));

	if (UserRolesChangedError?.length) {
		localStorage.clear();
		window.location.reload();
		return;
	}

	// if the access token is expired we need to try to fetch new access token
	if (JWTExpiredError?.length) {
		return fromPromise(
			refreshTokenRequest().catch(() => {
				// Handle token refresh errors e.g clear stored tokens, redirect to login, ...
				localStorage.clear();
				window.location.reload();
			})
		)
			.filter((value) => Boolean(value))
			.flatMap((accessToken: { data: string }) => {
				localStorage.setItem("dcbyte-jwt", accessToken.data);
				const oldHeaders = operation.getContext().headers;
				// modify the operation context with a new token
				operation.setContext({
					headers: {
						...oldHeaders,
						authorization: `Bearer ${accessToken.data}`
					}
				});

				// retry the request, returning the new observable
				return forward(operation);
			});
	}
});

const {
	ApolloClient: ApolloClientV2,
	InMemoryCache: InMemoryCacheV2
} = apolloV2;

export const clientV2 = new ApolloClientV2({
	link: errorLinkV2.concat(authLinkV2.concat(httpLink)),
	cache: new InMemoryCacheV2({
		// Changes the default cacheId which is "__typeName:id" 
		dataIdFromObject: (responseObject: { [key: string]: any }) => {
			switch (responseObject.__typename) {
			case "Company": return `${responseObject.__typename}: ${sha256(JSON.stringify(responseObject))}`;
			case "Site": return responseObject.publicId ? `${responseObject.__typename}:${responseObject.publicId}` : defaultDataIdFromObjectV2(responseObject);
			// The cacheId very based on the inputs due to currency differences (USD/local)
			case "MarketProp": return responseObject.hashedInputId
				? `${responseObject.__typename}: ${responseObject.hashedInputId}`
				: apolloV2.defaultDataIdFromObject(responseObject);
			// In another scenario, the cacheId is the same when loaded in USD
			// and in the local currency, but the property values are different.
			case "LandTransaction": return `${responseObject.__typename}: ${sha256(JSON.stringify(responseObject))}`;
			case "BuildingTransaction": return `${responseObject.__typename}: ${sha256(JSON.stringify(responseObject))}`;
			case "Transaction": return `${responseObject.__typename}: ${sha256(JSON.stringify(responseObject))}`;
			case "Region": return `${responseObject.__typename}: ${responseObject?.id}${responseObject?.name}${responseObject?.isPrimary}${responseObject?.remainingAssignedAnalystNames}`;
			case "AnalystPerformance":
				return `${responseObject.__typename}: ${responseObject?.analystPublicId}${responseObject?.year}${responseObject?.quarter}`;
			case "PrimaryMarketData": return `${responseObject.__typename}: ${responseObject?.id}${responseObject?.year}${responseObject?.quarter}`;
			default: return apolloV2.defaultDataIdFromObject(responseObject);
			}
		}
	}),
	connectToDevTools: true
});

export default client;
