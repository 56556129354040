// @ts-nocheck
import { createContext, Dispatch } from "react";
import { UserProp } from "app/GraphqlGeneratedTypes/types";

interface ActiveUserContextInterface {
	activeUser: UserProp;
	onUpdateActiveUser: Dispatch<any>;
	refetchActiveUser: () => void;
}

const ActiveUserContext = createContext<ActiveUserContextInterface>({
	activeUser: {
		publicId: "",
		email: "",
		fullName: ""
	},
	onUpdateActiveUser: () => {},
	refetchActiveUser: () => {}
});

ActiveUserContext.displayName = "ActiveUserContext";

export default ActiveUserContext;
