import React, {
	Suspense,
	useState,
	useEffect
} from "react";
import { Route, Switch } from "react-router-dom";
import { useApolloClient, useQuery } from "@apollo/client";
import jwt, { JwtPayload } from "jsonwebtoken";
import trackEvent, { amplitudeInit } from "app/utils/amplitudeTracking";
import { StatsigProvider } from "@statsig/react-bindings";

import {
	ActiveUserPropGqlRoutesQuery,
	ActiveUserPropGqlRoutesQueryVariables,
	UserProp
} from "app/GraphqlGeneratedTypes/types";
import {
	ACTIVE_USER_PROP,
	FETCH_APP_VERSION_QUERY
} from "./RoutesQueries";
import refreshTokenRequest from "./utils/refreshToken/refreshToken";
import ActiveUserContext from "app/context/ActiveUserContext";
import useStatsigClient from "app/utils/customHooks/useStatsigClient";

const NotFound = React.lazy(() => import("app/pages/Public/pages/NotFound/NotFound"));
const BannerWarnings = React.lazy(() => import("app/components/BannerWarnings"));
const AppDashboard = React.lazy(() => import("app/pages/AppDashboard/AppDashboard"));
const StaticMap = React.lazy(() => import("./pages/Public/pages/StaticMap/StaticMap"));

interface Props {
	location: LocationDOM;
}

const Routes: React.FC<Props> = (props) => {
	const [activeUser, setActiveUser] = useState<UserProp>(null);

	const { location } = props;

	const missingSavedToken = !localStorage.getItem("dcbyte-jwt");

	const activeUserQuery = useQuery<ActiveUserPropGqlRoutesQuery, ActiveUserPropGqlRoutesQueryVariables>(ACTIVE_USER_PROP, {
		fetchPolicy: "network-only",
		skip: missingSavedToken
	});

	const apolloHookClient = useApolloClient();

	const activeUserData = activeUserQuery?.data?.activeUserProp;

	useEffect(() => {
		amplitudeInit();
	}, []);

	const statsigClient = useStatsigClient(activeUser);

	useEffect(() => {
		trackEvent({
			event: "Page View",
			eventOptions: {
				page: location.pathname
			}
		});
	}, [location.pathname]);

	const loggedIn = localStorage.getItem("dcbyte-jwt");

	if (!window.location.href.includes("localhost")) {
		apolloHookClient.query({
			query: FETCH_APP_VERSION_QUERY,
			fetchPolicy: "network-only"
		}).then((result) => {
			if (sessionStorage.getItem("appVersion") && sessionStorage.getItem("appVersion") !== result.data.fetchAppVersion) {
				sessionStorage.setItem("appVersion", result.data.fetchAppVersion);
				window.location.reload();
			} else if (!sessionStorage.getItem("appVersion")) {
				sessionStorage.setItem("appVersion", result.data.fetchAppVersion);
				window.location.reload();
			}
		}).catch((error) => {
			if (!error.message.includes("All premium users must have accepted the premium access policy!")) {
				console.error("Error:", error);
			}
		});
	}

	const token = jwt.decode(loggedIn) as JwtPayload;

	if (loggedIn && token) {
		const { exp, publicId } = token;
		const now = Math.floor(Date.now() / 1000);

		if (exp <= now) {
			refreshTokenRequest()
				.then((res) => {
					localStorage.setItem("dcbyte-jwt", res.data);
				})
				.catch(() => {
					localStorage.clear();
					window.location.reload();
				});
		}

		const isActiveUserValid = publicId === activeUserData?.publicId;

		if (!isActiveUserValid) {
			try {
				activeUserQuery.refetch();
			} catch (err) {
				console.log(err);
			}
		}

		if (activeUserQuery.loading || activeUserQuery.error || !isActiveUserValid) {
			return <div/>;
		}

		if (activeUserData
			&& (
				!activeUser
				|| activeUser.publicId !== activeUserData.publicId
				|| activeUser.requirePasswordChange !== activeUserData.requirePasswordChange
			)) {
			setActiveUser(activeUserData);
		}
	} else if (activeUser) {
		setActiveUser(null);
	}

	return (
		<StatsigProvider client={statsigClient}>
			<div className="routes">
				<Suspense fallback={null}>
					<BannerWarnings />

					<Switch>
						<Route
							path="/static-map/"
							exact
							render={(routerProps) => <StaticMap
								{...routerProps}
								activeUser={activeUser}
							/>}
						/>

						<ActiveUserContext.Provider value={{
							activeUser,
							onUpdateActiveUser: setActiveUser,
							refetchActiveUser: activeUserQuery?.refetch
						}}>
							<Route path="/" render={(routerProps) => <AppDashboard
								{...routerProps}
								activeUser={activeUser}
							/>} />
						</ActiveUserContext.Provider>

						<Route path="*" render={(routerProps) => <NotFound {...routerProps} activeUser={activeUser}/>} />
					</Switch>
				</Suspense>
			</div>
		</StatsigProvider>
	);
};

export default Routes;
