import constants from "./constants";

/**
 * This function check if the build is production.
 * This function WILL NOT tell you the envornment where the code is running
 * e.g. dcbyte, qa, staging
 * We are making production build for each environment, the development
 * build is when we build on our local machines
 */
const isProductionBuild = () => {
	return process.env.NODE_ENV === constants.PROD_NODE_ENV;
};

/**
 * This function returns the current domain
 * where the code is running
 */
const getDomain = () => {
	switch (process.env.REACT_APP_DOMAIN) {
	case constants.PROD_REACT_APP_DOMAIN: // Production
		return "app.dcbyte.com";
	case constants.STAGING_REACT_APP_DOMAIN: // Staging
		return "staging.dcbyte.com";
	case constants.QA_REACT_APP_DOMAIN:
		return "qa.dcbyte.com";
	case constants.CYPRESS_REACT_APP_DOMAIN:
		return "cypress.dcbyte.com";
	default:
		return "localhost:8207";
	}
};

const getUrl = () => {
	let urlDomain = "";

	const domain = getDomain();

	switch (domain) {
	case "app.dcbyte.com":
		urlDomain = "https://app.dcbyte.com";
		break;
	case "staging.dcbyte.com":
		urlDomain = "https://staging.dcbyte.com";
		break;
	case "qa.dcbyte.com":
		urlDomain = "https://qa.dcbyte.com";
		break;
	default:
		urlDomain = "https://qa.dcbyte.com";
		break;
	}

	return urlDomain;
};

const getStatsigEnvironment = () => {
	let statsigEnvironment = "";

	switch (process.env.REACT_APP_DOMAIN) {
	case constants.PROD_REACT_APP_DOMAIN:
		statsigEnvironment = "production";
		break;
	case constants.STAGING_REACT_APP_DOMAIN:
		statsigEnvironment = "staging";
		break;
	case constants.QA_REACT_APP_DOMAIN:
		statsigEnvironment = "development";
		break;
	default:
		statsigEnvironment = "development";
		break;
	}

	return statsigEnvironment;
};

export {
	isProductionBuild,
	getDomain,
	getUrl,
	getStatsigEnvironment
};
