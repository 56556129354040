// @ts-nocheck
import JWT from "jsonwebtoken";
import amplitude from "amplitude-js";
import constants from "./constants";

// Flag (Singleton)
let initialized = false;

export const amplitudeInit = () : void => {
	if (process.env.REACT_APP_DOMAIN === constants.PROD_REACT_APP_DOMAIN && !initialized) {
		amplitude.init(process.env.REACT_APP_AMPLITUDE_KEY);
		initialized = true;
	} else if (process.env.REACT_APP_DOMAIN === constants.QA_REACT_APP_DOMAIN && !initialized) {
		amplitude.init(process.env.REACT_APP_QA_AMPLITUDE_KEY);
		initialized = true;
	}
};

interface trackEventInput {
	event: string;
	eventOptions?: object;
	userOptions?: object;
}

const trackEvent = (input: trackEventInput) : void => {
	const { event, eventOptions, userOptions } = input;
	if (!initialized) return;

	const jwt = localStorage.getItem("dcbyte-jwt");

	const user = JWT.decode(jwt);

	if (user) {
		const { publicId, email, roles } = user;

		const options = {
			email,
			company: user?.company?.name,
			roles
		};

		amplitude.setUserId(publicId);
		amplitude.setUserProperties({ ...userOptions, ...options });
	}

	amplitude.getInstance().logEvent(event, eventOptions);
};

export default trackEvent;
