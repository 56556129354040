import { gql } from "@apollo/client";

export const ACTIVE_USER_PROP = gql`
	query activeUserPropGqlRoutes {
		activeUserProp {
			isNotified
			publicId
			rights
			email
			subscription
			companyId
			fullName
			firstName
			lastName
			jobTitle
			businessAreaId
			companyName
			hasAcceptedPremiumSubscriberPolicy
			neverDisplayMarketsOverlayPopup
			requirePasswordChange
			enabledTwoFactorAuthentication
			status
			measurementSystem
			company {
				publicId
				name
				subscriptionType
				measurementSystem
				hasApiAccess
				regionsAccess
				majorShareholders {
					id
					name
				}
				logo {
					url
				}
				contacts {
					id
					name
					companyName
					role
					phoneNumber
					email
					contactType
				}
				datasets {
					id
					name
					dataType
				}
			}
			roles {
				name
			}
			analyticsPreferences {
				preferences {
					companyData {
						aggregateGroupCompanyData
					}
				}
			}
			profilePicture {
				publicId
				url
				name
			}
		}
	}
`;

export const FETCH_APP_VERSION_QUERY = gql`
	query fetchAppVersionRoutes {
        fetchAppVersion
    }
`;
